import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { ApolloClient as UploadApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory'

import { split, HttpLink } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { ApolloLink, Observable } from 'apollo-boost'
import { WebSocketLink } from '@apollo/client/link/ws'

import { ApolloClient as WsApolloClient } from 'apollo-client'
const apolloCache = new UploadInMemoryCache({
  addTypename: false,
})
const cache = new InMemoryCache({})

export let GoogleAPIKey =
  process.env.REACT_APP_API_URL === 'prod-build'
    ? 'AIzaSyAyjL0UnhVFYNJRALV5-4-vJkWJCEF2bQY'
    : 'AIzaSyCJLZ1tV4JTn3yvEka_e3J_J3eHALBi9AU'

let hotelxNodeUrl
switch (process.env.REACT_APP_API_URL) {
  case 'dev-build':
    hotelxNodeUrl = 'https://hotelx-dev-api.ifca.io/hotelx'
    break
  case 'prod-build':
    hotelxNodeUrl = 'https://hotelxapi.hotelx.asia/hotelx'
    break
  case 'uat-build':
    hotelxNodeUrl = 'https://hotelx-uat-api.ifca.io/hotelx'
    break
  default:
    hotelxNodeUrl = 'http://localhost:8000/hotelx'
    break
}
export let PaymentUrl = ''
switch (process.env.REACT_APP_API_URL) {
  case 'dev-build':
    PaymentUrl = 'https://hotelx-dev-ta.ifca.io'
    break
  case 'uat-build':
    PaymentUrl = 'https://collaborative-platform-uat.hotelx.asia'
    break
  case 'prod-build':
    PaymentUrl = 'https://collaborative-platform.hotelx.asia'
    break
  default:
    PaymentUrl = 'http://localhost:3303'
    break
}
// export const wsUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'wss://localhost:8000/hotelx'
//     : 'wss://hotelx-dev-api.ifca.io/hotelx'

// const wsLink = new WebSocketLink({
//   uri: wsUrl,
//   options: {
//     reconnect: true,
//   },
// })

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
// const httplink = new HttpLink({
//   uri: hotelxNodeUrl,
//   credentials: 'include',
// }) //new HttpLink ends here

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     )
//   },
//   wsLink,
//   httplink
// )

// const cache = new InMemoryCache({})
// export const WSclient = new WsApolloClient({
//   cache: cache,
//   link: ApolloLink.from([splitLink as any]),
// })

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = JSON.parse(localStorage.getItem('DebtorInfo'))
            ?.accessToken
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const uploadLink = createUploadLink({
  uri: hotelxNodeUrl,
  credentials: 'include',
})

export const Uploadclient = new UploadApolloClient({
  cache: apolloCache,
  link: ApolloLink.from([requestLink, uploadLink as any]),
})

export const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  uri: hotelxNodeUrl,
  request: operation => {
    // const token = localStorage.getItem('token')
    const accessToken = JSON.parse(localStorage.getItem('DebtorInfo'))
      ?.accessToken
    operation.setContext({
      headers: {
        'keep-alive': 'true',
        authorization: `bearer ${accessToken}`,
      },
    })
  },
  onError: error => {
    error?.graphQLErrors?.map(({ message }) => {

      // if (
      //   message ===
      //   'Context creation failed: Authentication token is invalid, please log in'
      // ) {
      //   // browserHistory.push('/login', {})
      // }
    })
  },
})
